import { Box, Text, VStack } from '@chakra-ui/react';
import { Icons, Link, SvgSpriteConsumer } from '@pluxee-design-system/core';
import YTVideo from 'common/feedback/YTVideo';
import HtmlText from 'common/typography/HtmlText';
import useModal from 'context/ModalContext';
import usePageVideo from 'data/usePageVideo';
import markupParser from 'html-react-parser';
import useTranslations from 'i18n';
import { useCallback } from 'react';
import { PageVideoEnum } from 'types/videos';

interface VideoButtonProps {
  page: PageVideoEnum; // to display the video button on a page
}

const VideoButton = ({ page }: VideoButtonProps) => {
  const { t } = useTranslations();
  const showModal = useModal();
  const video = usePageVideo(page);

  const handleClick = useCallback(async () => {
    if (video)
      await showModal({
        children: (
          <VStack gap={4}>
            <YTVideo w="100%" alt={video?.header} link={video.youtube_link} />
            {(video.header || video.description) && (
              <Box alignSelf="flex-start">
                {video.header && (
                  <Text as="h3" variant="title.desktopCard" color="semantic.text.primary" mb={2}>
                    {video.header}
                  </Text>
                )}
                {video.description && (
                  <HtmlText variant="subtitle.desktopMain" color="semantic.text.tertiary">
                    {markupParser(video.description)}
                  </HtmlText>
                )}
              </Box>
            )}
          </VStack>
        ),
      });
  }, [showModal, video]);

  if (!video) return null;

  return (
    <Link
      onClick={handleClick}
      leftIcon={<SvgSpriteConsumer size={16} svgId={Icons.QUESTION_FILLED16} />}
      size="small"
      mb={2}
    >
      {t('videos.buttons.help', 'Get help')}
    </Link>
  );
};

export default VideoButton;
