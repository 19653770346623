import { formatPhoneNumberIntl } from 'react-phone-number-input';

const spaces = /\s/g;

const stripPhoneNumber = (phoneNumber: string) => {
  return phoneNumber
    ? formatPhoneNumberIntl(phoneNumber).replace(spaces, '') ?? phoneNumber
    : phoneNumber;
};

export default stripPhoneNumber;
