import { DefaultApiFactory } from 'generated';
import { instanceApi } from 'utils/axios';
import useRequest from './useRequest';

const defaultApi = DefaultApiFactory(undefined, '', instanceApi);

export const useBanks = () => useRequest(defaultApi.banks, []);

export const useDepartments = () => useRequest(defaultApi.departments, []);

export const useLegalForms = (enabled: boolean = true) =>
  useRequest(defaultApi.legalFormsList, [], undefined, enabled);

export const useResponsibilities = () => useRequest(defaultApi.responsibilities, []);

export const useUserRoles = (contractId?: string) =>
  useRequest(defaultApi.userrolesList, [contractId], undefined, contractId !== undefined);

export default defaultApi;
