const protocol =  /^(http:\/\/|https:\/\/)?/i;

const stripUrl = (url?: string) => {
  if (url) {
    return url.replace(protocol, '');  
  }
  return url;
}

export default stripUrl;
