import { ProductsApiFactory } from 'generated';
import { instanceApi } from 'utils/axios';
import useRequest from './useRequest';

const productsApi = ProductsApiFactory(undefined, '', instanceApi);

export const useProducts = (
  services?: string[],
  contractUid?: string,
  campaignId?: string,
  alwaysFetch?: boolean,
) =>
  useRequest(
    productsApi.products,
    [undefined, services, contractUid, campaignId],
    undefined,
    (services !== undefined && services.length > 0) ||
      Boolean(services === undefined && contractUid) ||
      alwaysFetch,
  );

export default productsApi;
