import { useUserRoles } from 'api/defaultApi';
import { EllipseStatusVariant } from 'common/feedback/EllipseStatus';
import {
  Employee,
  LocationEmployeeOption,
  PortalAccessStatusEnum,
  UserRoleEnum,
} from 'generated/models';
import { TranslateFn } from 'i18n';
import { useMemo } from 'react';
import { AssignedLocationState, UserRoleType } from './types';

export const getNameInitials = (fullName: any) => {
  const initials = fullName.toUpperCase().split(' ');

  if (initials.length > 1) {
    return initials.shift().charAt(0) + initials.pop().charAt(0);
  } else {
    return fullName.substring(0, 2);
  }
};

export const getPortalAccessVariant = (
  portalAccess?: PortalAccessStatusEnum,
): `${EllipseStatusVariant}` => {
  switch (portalAccess) {
    case PortalAccessStatusEnum.Active:
      return 'shipped';
    case PortalAccessStatusEnum.Invited:
      return 'inProgress';
    default:
      return 'expired';
  }
};

export const useAccessRoles = (t: TranslateFn, contractId?: string, filterRole?: UserRoleEnum) => {
  const { data } = useUserRoles(contractId);

  return useMemo<UserRoleType[]>(
    () =>
      (data?.entries || [])
        .filter((e) => (filterRole ? e.code === filterRole || e.enabled : e.enabled))
        .map((e) => ({
          key: e.code,
          name: t(`ngm_access_roles.ngmAccessRoles.${e.code}`, e.name),
          text: t(`ngm_access_roles.ngmAccessRolesDesciption.${e.code}`, e.name),
        })),
    [data, filterRole, t],
  );
};

export const getDiffAssignedLocations = (
  contactLocations: Record<string, AssignedLocationState>,
  accessLocations: Record<string, AssignedLocationState>,
): LocationEmployeeOption[] => {
  const result: Record<string, LocationEmployeeOption> = {};

  Object.keys(contactLocations).forEach((key) => {
    if (contactLocations[key].oldState !== contactLocations[key].newState) {
      if (!result[key]) {
        result[key] = { locationUid: key };
      }
      result[key].contact = contactLocations[key].newState;
    }
  });

  Object.keys(accessLocations).forEach((key) => {
    if (accessLocations[key].oldState !== accessLocations[key].newState) {
      if (!result[key]) {
        result[key] = { locationUid: key };
      }
      result[key].editor = accessLocations[key].newState;
    }
  });

  return Object.values(result);
};

export const countAssignedLocations = (locations: Record<string, AssignedLocationState>) =>
  Object.values(locations).reduce((result, current) => {
    if (current?.newState) {
      return result + 1;
    }
    return result;
  }, 0);

export const hasActivePortalAccess = (employee: Employee) =>
  employee.portalAccess && employee.portalAccessStatus === PortalAccessStatusEnum.Active;

// decide if locations can be assigned to a user role
export const hasAccessToAllLocations = (role: UserRoleEnum) => {
  switch (role) {
    case UserRoleEnum.Clerk:
    case UserRoleEnum.Operator:
      return false;
    case UserRoleEnum.Admin:
    case UserRoleEnum.AdminExec:
    case UserRoleEnum.BoExt:
    case UserRoleEnum.BoInt:
    default:
      return true;
  }
};
