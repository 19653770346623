import { CampaignType } from 'types/campaign';
import { LocationConfig } from 'types/config';
import { Activity, ContractProduct, LocationTypeEnum, PosTypeEnum } from 'generated/models';
import { ActivitySelection } from 'components/Form/ActivityTreeFieldArray';

export enum PaymentTypeCodeEnum {
  PSP = 'PSP',
  Terminal = 'TERMINAL',
  ScanR = 'SCAN_R',
  CashRegister = 'CASH_REGISTER',
  Payconiq = 'PAYCONIQ',
  Marketplace = 'MARKETPLACE',
  UberEat = 'UBEREAT',
  Delivroo = 'DELIVROO',
  Phenix = 'PHENIX',
  TakeAway = 'TAKE_AWAY',
}

// TODO: remove and use PaymentTypeCodeEnum ?
export enum PaymentOptionEnum {
  Marketplace = 'marketplace',
  Payconiq = 'payconiq',
  POS = 'pos',
  PSP = 'psp',
}

export interface TerminalProviders {
  providers: Array<{ value: string; label: string }>;
  tids: Record<string, string>;
}

// string literal type (TS 4.1+)
export type PaymentTypeState =
  | `${string}.${string}.${string}` // paymentType + productCode + locationType
  | `${null}.${string}.${string}` // any + productCode + locationType
  | `${null}.${null}.${string}`; // any + any + locationType

export type PaymentTypeDict = Record<string, boolean>;

export type IsEnabledFn = (
  paymentTypeCode: PaymentTypeCodeEnum | null,
  productCodes: string[] | null,
  locationType: LocationTypeEnum | '',
) => boolean;

export interface ExtraWizardProps<C = LocationConfig> {
  isOnboarding?: boolean;
  isSelfcareEnabled?: boolean;
  isSkippable?: boolean;
  isPaymentEnabled?: IsEnabledFn;
  activities?: Activity[];
  campaign?: CampaignType;
  contractProducts?: ContractProduct[];
  contractId: string;
  config: C;
  terminalProviders?: TerminalProviders;
}

export enum StepStatusEnum {
  Active,
  NotActive,
  Validated,
}

export interface CzechPOSValues {
  country: 'cz';
  id: string;
  bankId: string;
  deviceType: PosTypeEnum;
  productIds: string[];
  terminalId: string;
  terminalCode: string;
  masterId: string;
  email: string;
  certificateEmail: string;
}

export interface BelgiumPOSValues {
  country: 'be';
  id: string;
  terminalId: string;
  terminalProvider: string;
}

export type POSValues = CzechPOSValues | BelgiumPOSValues;

export interface CzechOnboardingValues {
  country: 'cz';
  name: string;
  streetName: string;
  houseNumber: string;
  city: string;
  postalCode: string;
  webSiteUrl: string;
  email: string;
  phoneNumber: string;
  secondaryPhoneNumber: string;
  wifi: boolean;
  activities: ActivitySelection[];
  products: string[];
  merchantLocationId: string;
  POS: CzechPOSValues[];
}

export interface BelgiumOnboardingValues {
  country: 'be';
  type: LocationTypeEnum | '';
  sameCompanyInfo: boolean;

  locationName: string;

  // physical
  streetName: string;
  houseNumber: string;
  box: string;
  city: string;
  postalCode: string;
  terminalDelayDate: string | Date | null;
  POS: BelgiumPOSValues[];

  // webshop
  website: string;
  email: string;
  terminalProviderOnline: string;
  terminalIdOnline: string;

  phoneNumber: string;
  typeOfActivity: string;
  products: string[];
  
  paymentOptions: PaymentOptionEnum[];
  
  accountNumber: string;
}

export interface BelgiumValidationContext {
  isPaymentOptionEnabled: (code: PaymentTypeCodeEnum) => boolean;
  isProductEnabled: (productCode: string) => boolean;
  isTIDValid: (terminalId: string) => boolean;
}

// TODO: maybe use Discriminated Unions?
export type OnboardingValues = CzechOnboardingValues | BelgiumOnboardingValues;
