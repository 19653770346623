import { Box, BoxProps } from '@chakra-ui/react';

interface YTVideoProps extends BoxProps {
  link?: string;
  alt?: string;
}

const YTVideo = ({
  alignSelf = 'center',
  alt,
  link,
  height = 253,
  h = 253,
  ...rest
}: YTVideoProps) => {
  if (!link) return null;

  return (
    <Box
      {...rest}
      alignSelf={alignSelf}
      h={h ?? height}
      as="iframe"
      title={alt}
      sandbox="allow-same-origin allow-popups-to-escape-sandbox allow-forms allow-popups allow-scripts allow-presentation"
      src={link.replace('watch?v=', 'embed/')}
    />
  );
};

export default YTVideo;
