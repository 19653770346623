import {
  Contract,
  ContractProduct,
  Location,
  LocationStatusEnum,
  ProductItem,
  ProductStatusEnum,
  ProductTypeEnum,
} from 'generated/models';
import { SessionUserType } from 'types/common';
import { RemittanceSheetFormValues, VoucherOption } from './types';

export const template: VoucherOption = {
  productId: '',
  nominalValue: '',
  vouchersCount: '',
  totalValue: '',
};

export const filterPaperProduct = (p: ProductItem & ContractProduct) =>
  p.product.type === ProductTypeEnum.Paper &&
  (p?.productStatus === ProductStatusEnum.Active || p?.status === ProductStatusEnum.Active);

export const filterActiveLocation = (l: Location) =>
  l.products?.some(filterPaperProduct) && l.status === LocationStatusEnum.Active;

export const getInitialValues = (
  initial: RemittanceSheetFormValues,
  contractDetail?: Contract,
  locations?: Location[],
  currentUser?: SessionUserType,
): RemittanceSheetFormValues => {
  const company = contractDetail?.company;
  const address = company?.addrHeadquarters;
  const bank = contractDetail?.bankAccount;
  const account = bank?.accountNumber?.split('-');
  const location = locations?.length === 1 ? locations[0] : undefined;
  const product = location?.products?.filter(filterPaperProduct);

  if (product?.length === 1) {
    template.productId = product[0].product.nameKey;
  }

  return {
    ...initial,
    companyName: company?.name ?? initial.companyName,
    masterId: contractDetail?.masterId ?? initial.masterId,
    companyId: company?.cin ?? initial.companyId,
    vatNumber: company?.vatin ?? initial.vatNumber,

    streetName: address?.street ?? initial.streetName,
    houseNumber: address?.houseNumber ?? initial.houseNumber,
    city: address?.city ?? initial.city,
    postalCode: address?.zip ?? initial.postalCode,

    bank: bank?.bankId?.toString() ?? initial.bank,
    accountNumber: account?.pop() ?? initial.accountNumber, // first pop last
    bankNumber: account?.[0] ?? initial.bankNumber, // then index if defined

    phoneNumber: currentUser?.telephone ?? initial.phoneNumber,
    locationId: location?.uid ?? initial.locationId,
    currentLocation: location,

    vouchers: [{ ...template }],
  };
};

export const countTotal = (
  key: keyof Omit<VoucherOption, 'productId'>,
  ...vouchers: VoucherOption[]
): number =>
  vouchers.reduce((result, voucher) => {
    try {
      const value = voucher[key];
      return result + (value !== '' ? value : 0);
    } catch (e) {
      return result;
    }
  }, 0);

/**
 * Indices sorted in ascending order.
 *
 * @param newLocation
 * @param vouchers
 */
export const findVouchersToRemove = (
  newLocation: Location,
  vouchers: VoucherOption[],
): [number[], string[]] => {
  const vouchersToRemove: number[] = [];
  const productKeys = new Set<string>();
  vouchers.forEach(({ productId }, index) => {
    if (productId && newLocation.products?.every((p) => p.product.nameKey !== productId)) {
      vouchersToRemove.push(index);
      productKeys.add(productId);
    }
  });
  return [vouchersToRemove, Array.from(productKeys)];
};

export const getSelectLabel = (loc: Location) => loc?.masterId || loc?.name;
export const getSelectValue = (loc: Location) => loc?.masterId;
