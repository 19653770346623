export enum PageVideoEnum {
  // Affiliation steps
  Affiliation = 'affiliation',
  AffiliationCompanyId = 'affiliation_company_id',
  AffiliationCompanyInfo = 'affiliation_company_info',
  AffiliationContactInfo = 'affiliation_contact_info',
  AffiliationProduct = 'affiliation_product',
  AffiliationPaymentInfo = 'affiliation_payment_info',
  AffiliationContractConfirmation = 'affiliation_contract_confirmation',

  // Selfcare
  Invoices = 'bam_invoices',
  Packages = 'bam_packages',
  PerformanceReport = 'bam_performance',
  Transactions = 'bam_transactions',
  Contract = 'contract',
  Dashboard = 'dashboard',
  Employees = 'employees',
  EmployeeAdd = 'employee_add',
  EmployeeDetail = 'employee_detail',
  Locations = 'locations',
  LocationAdd = 'location_add',
  LocationDetail = 'location_detail',
  Onboarding = 'onboarding',
  Payments = 'payments',
  Products = 'products',
  Promotions = 'promotions',
  RemittanceSheet = 'remittance_sheet',

  // The rest
  Invitation = 'invitation',
}

export interface VideoItemType {
  header: string;
  description: string;
  page: PageVideoEnum[];
  visible_on_page: boolean;
  visible_on_support: boolean;
  youtube_link: string;
}
