import useConfig from 'data/useConfig';
import useTranslations from 'i18n';
import { useMemo } from 'react';
import { PageVideoEnum, VideoItemType } from 'types/videos';

const usePageVideo = (page: PageVideoEnum) => {
  const { videos: isVideoEnabled } = useConfig();
  const { tGroup } = useTranslations();

  return useMemo<VideoItemType | undefined>(() => {
    if (!isVideoEnabled) return undefined;

    const videos = tGroup('videos.videos').map((v) => v?.video) as VideoItemType[];
    const allowedVideos = videos.filter((v) => v?.page?.includes(page) && v?.visible_on_page);

    return allowedVideos?.[0];
  }, [isVideoEnabled, page, tGroup]);
};

export default usePageVideo;
